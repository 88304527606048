<template>
  <v-card class="pa-4">
    <div class="form-section-heading mb-1">Search engine listing (SEO)</div>
    <div class="caption">
      Add a title and description to see how this {{ resourceName }} might
      appear in a search engine listing
    </div>
    <v-dynamic-form
      class="mt-2"
      v-model="data"
      v-model:valid="valid.seo"
      :inputs="inputs"
      :readonly="!canEdit"
    />
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Rule } from "~/services/Rules";

export default defineComponent({
  props: {
    resourceName: { type: String, default: "item" },
  },
  setup() {
    const { getValid, data, canEdit } = useSaveContext<any>();

    const valid = getValid();

    return {
      valid,
      data,
      canEdit,
    };
  },
  data: () => ({
    inputs: defineFormInputs({
      seo_title: {
        name: "Title",
        rules: Rule.title(),
        type: "text",
        props: {
          placeholder: "Prime Afro Gala of " + new Date().getFullYear(),
        },
      },
      seo_description: {
        name: "Description",
        rules: Rule.short(),
        type: "textarea",
        props: {
          // outlined: true,
          counter: 320,
          rows: 3,
          noResize: true,
        },
      },
    }),
  }),
});
</script>
